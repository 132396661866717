import React from "react"
import addToMailchimp from  'gatsby-plugin-mailchimp'

// This signup form is for the home page
class Substack extends React.Component{
    
    render(){
        return(
            <>

            <iframe src="https://seekingclarity.substack.com/embed" width="480" height="320" style= {{justifyContent:"center", margin: "auto", display:"flex", paddingTop: "0", marginTop: "0em", border: 'none',background: 'white', frameborder: "0", scrolling:"no"}}></iframe>

            </>
        )
    }
}

export default Substack