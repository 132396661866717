import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Navbar from '../components/navbar'
import Img from "gatsby-image"
import Substack from "../components/subStack"
import SubstackChange1 from "../components/subStackChange"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Sean Cheng"

    return (
      <Layout location={this.props.location} title={siteTitle}>  
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
       
  
        <div style={{fontFamily: 'Inter', fontWeight: '400', }}>
       
        <p>Hey there, I’m Sean! <span role="img" aria-label="wave emoji">
            👋
          </span> I’m based out of San Francisco where I work on the software strategy team at Bain. </p>

         <p>Previously, I was a marketer at Stitch Fix and a jack-of-all-trades doing product, growth, and strategy/ops at various startups from pre-seed to Series E. </p>
         <p>As an undergrad, I studied political science and computer science at Swarthmore College and the University of Oxford.</p> 
          
        <p>I founded my school’s startup accelerator and <a href="https://swarthmorelaunchdeck.com/">entrepreneurship community</a>, worked as a fellow with <a href="https://www.bvp.com/news/bessemers-2022-fellowship-class">Bessemer Venture Partners</a>, and launched <Link to="/projects">several projects</Link> that have since helped thousands of people.</p>

         <p>In my free time, I enjoy learning about history, writing stories, and hanging out with friends/family. I love meeting new people so feel free to reach out over <a href="https://twitter.com/sean_cheng_">Twitter</a> or <a href="https://www.linkedin.com/in/sean-c-7b0a31192/">LinkedIn </a> if you’d like to chat! </p>
         
      </div>

    
        <Link to="/blog">
          
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
